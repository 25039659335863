<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0" v-if="!$route.params.slug">
      <b-card>
        <template #header>
          <b-card-title class="font-weight-bold" title-tag="h6">Grafik Data Sentimen Negatif</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart :option="negativeSentimentOptions" class="chart has-fixed-height" />
        </div>
      </b-card>
      <b-row>
        <b-col md="9">
          <div class="card">
            <div class="card-body">
              <div class="cat_switch">
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-earth"></i>
                  </div>
                  <p>All</p>
                  <h3>1428</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_fb">
                    <i class="icon-facebook"></i>
                  </div>
                  <p>Facebook</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_ig">
                    <i class="icon-instagram"></i>
                  </div>
                  <p>Instagram</p>
                  <h3>11</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-twitter"></i>
                  </div>
                  <p>Twitter</p>
                  <h3>0</h3>
                </div>
                 <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-newspaper"></i>
                  </div>
                  <p>News</p>
                  <h3>191</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-youtube"></i>
                  </div>
                  <p>Youtube</p>
                  <h3>191</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-newspaper2"></i>
                  </div>
                  <p>Blog</p>
                  <h3>215</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-sphere"></i>
                  </div>
                  <p>Web</p>
                  <h3>340</h3>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-7">
                  
                </div>
                <div class="col-md-5">
                  <div class="form-group mb-0">
                    <b-input-group>
                      <b-form-input class="border-right-0" placeholder="Search..." />
                      <template #append>
                        <b-btn class="bg-indigo-400">Search</b-btn>
                      </template>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label> Filter by</label>
                  <b-form-select v-model="selFilter" :options="filterOptions" />
                </div>
                <div class="col-md-12 mt-3">
                  <label> Filter by Sentiment</label>
                  <b-form-select v-model="selSentiment" :options="sentimentOptions" />
                </div>
                <!--<div class="col-md-12 mt-3">
                  <label>Filter by Score</label>
                  <input type="text" class="form-control ion-height-helper" id="ion-start" data-fouc>
                </div>-->
              </div>
            </div>
          
          </div>
        </b-col>
      </b-row>
      <div
        class="card_mention"
        v-for="(mention, index) in mentions"
        :key="index"
        :class="{
          'bg_positive': mention.sentiment == 'Positif',
          'bg_negative': mention.sentiment == 'Negatif',
        }"
      >
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="form-check pl-1 mb-0 mr-1">
                <label class="form-check-label">
                  <b-form-checkbox class="form-check-input-styled" />
                </label>
              </div>
              <div class="mr-3">
                <b-button class="btn-icon btn-sm rounded-round"
                  :class="{
                    'bg_ig': mention.type == 'ig',
                    'bg_news': mention.type == 'news',
                    'bg_yt': mention.type == 'yt',
                    'bg_tw': mention.type == 'tw',
                  }"
                >
                  <i
                    :class="{
                      'icon-instagram': mention.type == 'ig',
                      'icon-newspaper': mention.type == 'news',
                      'icon-youtube': mention.type == 'yt',
                      'icon-twitter': mention.type == 'tw',
                    }"></i>
                </b-button>
              </div>
              <div>
               <a href="#" class="text-default font-weight-bold letter-icon-title">{{ mention.username }}</a>
                <div class="text-muted font-size-sm">
                  <span
                    :class="`badge badge-mark mr-1 ${mention.status == 'Active' ? 'border-blue':'border-gray'}`"></span> {{ mention.status }}
                </div>
                <!-- <div class="rating_score" v-html="starMaker(5, mention.rating)">
                </div> -->
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <h3><a href="">{{ mention.title }}</a></h3>
              <h4><a href="">{{ mention.source }}</a></h4>
              <p>
                {{ mention.description }}
              </p>
            </div>
          </b-col>
          <b-col md="2" class="text-right">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> {{ mention.date | moment('ll') }}</span>
            </a>
            <div class="mt-2">
              <b-button
                size="sm"
                variant="transparent"
                class="btn-icon bg-indigo-400 mr-1"
                v-b-tooltip.hover="'Tambahkan Penangkalan Sentimen'"
                :to="{ name: 'SentimentList', params: { slug: index+1 } }"
              >
                <i class="icon-pencil5"></i>
              </b-button>
              <b-button size="sm" variant="outline-secondary" class="btn-icon">
                <i class="icon-trash-alt"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-card>
        <ul class="pagination pagination-flat align-self-center">
          <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
          <li class="page-item active"><a href="#" class="page-link">1</a></li>
          <li class="page-item"><a href="#" class="page-link">2</a></li>
          <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
          <li class="page-item"><a href="#" class="page-link">4</a></li>
          <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
        </ul>
      </b-card>
    </div>
    <div class="content pt-0" v-else>
      <b-card no-body>
        <div class="header_card mb-0">
          <h5 class="card-title font-weight-bold">Tambah Penangkalan Sentimen</h5>
        </div>
        <b-card-body>
          <div class="form-group">
            <label for="">Item Topik</label>
            <div
              class="card_mention"
              :class="{
                'bg_positive': mentions[$route.params.slug - 1].sentiment == 'Positif',
                'bg_negative': mentions[$route.params.slug - 1].sentiment == 'Negatif',
              }"
            >
              <b-row>
                <b-col md="3">
                  <div class="d-flex align-items-center">
                    <div class="form-check pl-1 mb-0 mr-1">
                      <label class="form-check-label">
                        <b-form-checkbox class="form-check-input-styled" />
                      </label>
                    </div>
                    <div class="mr-3">
                      <b-button class="btn-icon btn-sm rounded-round"
                        :class="{
                          'bg_ig': mentions[$route.params.slug - 1].type == 'ig',
                          'bg_news': mentions[$route.params.slug - 1].type == 'news',
                          'bg_yt': mentions[$route.params.slug - 1].type == 'yt',
                          'bg_tw': mentions[$route.params.slug - 1].type == 'tw',
                        }"
                      >
                        <i
                          :class="{
                            'icon-instagram': mentions[$route.params.slug - 1].type == 'ig',
                            'icon-newspaper': mentions[$route.params.slug - 1].type == 'news',
                            'icon-youtube': mentions[$route.params.slug - 1].type == 'yt',
                            'icon-twitter': mentions[$route.params.slug - 1].type == 'tw',
                          }"></i>
                      </b-button>
                    </div>
                    <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">{{ mentions[$route.params.slug - 1].username }}</a>
                      <div class="text-muted font-size-sm">
                        <span
                          :class="`badge badge-mark mr-1 ${mentions[$route.params.slug - 1].status == 'Active' ? 'border-blue':'border-gray'}`"></span> {{ mentions[$route.params.slug - 1].status }}
                      </div>
                      <!-- <div class="rating_score" v-html="starMaker(5, mentions[$route.params.slug - 1].rating)">
                      </div> -->
                    </div>
                  </div>
                </b-col>
                <b-col md="7">
                  <div class="card_content">
                    <h3><a href="">{{ mentions[$route.params.slug - 1].title }}</a></h3>
                    <h4><a href="">{{ mentions[$route.params.slug - 1].source }}</a></h4>
                    <p>
                      {{ mentions[$route.params.slug - 1].description }}
                    </p>
                  </div>
                </b-col>
                <b-col md="2" class="text-right">
                  <a href="#" class="text-default">
                    <span class="text-muted"><i class="icon-calendar mr-2"></i> {{ mentions[$route.params.slug - 1].date | moment('ll') }}</span>
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group label="Penangkalan Oleh" label-for="counterBy">
                <b-form-select id="counterBy" :options="counterBy" v-model="selCounterBy" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-card class="shadow-none border" title="Aksi Tangkal Isu" title-tag="h5">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr v-for="(v, k) in actions" :key="k">
                  <td>
                    <b-button size="sm" variant="outline-success" class="btn-icon">
                      <i class="icon-plus2"></i>
                    </b-button>
                    <b-button size="sm" variant="outline-danger" class="btn-icon ml-1">
                      <i class="icon-minus2"></i>
                    </b-button>
                  </td>
                  <td>
                    <b-form-group label="Media" label-for="counterMedia" class="mb-0">
                      <b-form-select id="counterMedia" :options="counterMedia" v-model="v.selCounterMedia" />
                    </b-form-group>
                  </td>
                  <template>
                    <td>
                      <b-form-group v-if="v.selCounterMedia == 'web'" label="Kategori Website" label-for="counterMediaWeb" class="mb-0">
                        <b-form-select id="counterMediaWeb" :options="counterMediaWeb" v-model="v.selCounterMediaWeb" />
                      </b-form-group>
                      <b-form-group v-else-if="v.selCounterMedia == 'socmed'" label="Platform Media Sosial" label-for="counterMediaSocmed" class="mb-0">
                        <b-form-select id="counterMediaSocmed" :options="counterMediaSocmed" v-model="v.selCounterMediaSocmed" />
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="URL" v-if="v.selCounterMedia == 'web'" label-for="counterMediaWebUrl" class="mb-0">
                        <b-form-input id="counterMediaWebUrl" v-model="v.url" />
                      </b-form-group>
                      <b-form-group label="Username Akun" v-else-if="v.selCounterMedia == 'socmed'" label-for="counterMediaSocmedAccount" class="mb-0">
                        <b-form-input id="counterMediaSocmedAccount" v-model="v.account" />
                      </b-form-group>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </b-card>
          <div class="text-right">
            <b-button variant="transparent" class="mr-1">Batal</b-button>
            <b-button variant="transparent" class="bg-indigo-400">Submit</b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>

<script>
import chartDatas from '@/dummies/chartDatas.js'
import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    PieChart,
    LineChart,
  } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  } from "echarts/components";
  import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

  use([
    CanvasRenderer,
    PieChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  ])
export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return{
      dateRange: {},
      sentiments: ['Netral', 'Positif', 'Negatif'],
      marking: [
        { text: 'Tandai', value: '' },
        { text: 'Ubah Sentimen', value: 'ubah' },
        { text: 'Tangkal Sentimen', value: 'tangkal' },
      ],
      mentions: [
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'BacaDiBaBe',
          description: 'Prabowo Tawarkan Anggaran Pertahanan Dialihkan untuk Berantas Covid, Jokowi Beri Jawaban',
          source: 'twitter.com',
          date: '2021-08-17',
          mark: 'pos',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
         // username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'KD_Indones',
          description: 'ROMO KEMERDEKAAN!!!️🤍 Mari nikmati waktu santai bersama keluarga dengan paket BAGE WAYVI O-nya. Paket berisi: - 3 Pempek Kapal Selam - 3 Pempek Telur Kecil - 3 Pempek Lenjer Kecil - 3 Pempek Adaan #indonesiamerdeka #kemerdekaanindonesia #indonesiamaju #kulinernusantara #pempekjakarta #pempekpaketmurah #kapalselam',
          source: 'instagram.com',
          date: '2021-08-14',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'RAPBN 2022 Kementerian yang dipimpin Prabowo Subi',
          description: 'Nota Keuangan RAPBN 2022 Presiden Joko Widodo tidak menyinggung rencana anggaran pertahanan tahun depan. Kementerian Pertahanan yang dipimpin Menteri Pertahanan Prabowo Subianto',
          source: ' nasional.kontan.co.id',
          date: '2021-08-13',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Prabowo Tawarkan Anggaran Pertahanan Dialihkan unt',
          description: 'Tawarkan Anggaran Pertahanan Dialihkan untuk Berantas Covid, Jokowi Beri Jawaban. Rio Rizky Pangestu 17 Agustus 2021, 09:23',
          source: ' pikiran-rakyat.com',
          date: '2021-08-11',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'yt',
          //username: '@integradesign',
          status: 'Active',
         // rating: 3,
          title: 'SmartLapak',
          description: 'Dr Connie Rahakundini Bakrie pengamat militer berbicara tegas dan jelas soal sistem pertahanan maritim indonesia sekarang tidak sebanding dengan ...',
          source: 'instagram.com',
          date: '2021-08-09',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'abufauzan1924',
          description: 'Pernah Tawarkan Anggaran Pertahanan, Prabowo: Pak Jokowi Sedang All Out https://bit.ly/3m4VPER',
          source: 'instagram.com',
          date: '2021-08-09',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'OposisiCerdas',
          description: 'Prabowo Ngaku Pernah Usul ke Jokowi Agar Anggaran Pertahanan Dialihkan Untuk Penanganan Covid-19 https://www.oposisicerdas.com/2021/08/prabowo-ngaku-pernah-usul-ke-jokowi.html',
          source: 'twitter.com',
          date: '2021-08-08',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'rmol_id',
          description: '@Prabowo Ngaku Pernah Usul ke @Jokowi Agar Anggaran Pertahanan Dialihkan Untuk Penanganan Covid-19 https://politik.rmol.id/read/2021/08/16/500718/prabowo-ngaku-pernah-usul-ke-jokowi-agar-anggaran-pertahanan-dialihkan-untuk-penanganan-covid-19',
          source: 'twitter.com',
          date: '2021-08-07',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Anggaran Pertahanan Israel Naik 2,15 Miliar Dolar AS',
          description: 'Anggaran pertahanan tersebut meningkat sebesar 2,15 miliar dolar AS dari tahun 2020. YERUSALEM — Menteri pertahanan dan keuangan Israel akan mengalokasikan 58 miliar shekel atau 17,8 miliar dolar AS untuk keperluan, pertahanan pada 2022. Anggaran pertahanan tersebut meningkat sebesar 2,15 miliar dolar AS dari tahun sebelumnya.',
          source: ' harianaceh.co.id.com',
          date: '2021-08-07',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Top! Prabowo Boyong 6 Jet Tempur Baru Rp 3,4 T dari K',
          description: 'Perusahaan Misterius PT TMI & Belanja Alutsista Rp1.760 T. Ramai Anggota DPR Desak Connie Ungkap Mr M Mafia Alutsista. Reaksi Prabowo Soal Heboh Mister M Sang Mafia Alutsista. Video Gerilya .',
          source: 'cnbcindonesia.com',
          date: '2021-08-06',
          mark: '',
          sentiment: 'Netral',
        },
      ],
      sentimentPieOptions: chartDatas.sentimentPieOptions,
      mediaPieOptions: chartDatas.mediaPieOptions,
      negativeSentimentOptions: chartDatas.negativeSentimentOptions,
      filterOptions: [
        { text: '-- Tampilkan Semua --', value: '' },
        { text: 'Media Sosial', value: 'sm' },
        { text: 'Web', value: 'w' },
      ],
      selFilter: '',
      sentimentOptions: [
        { text: '-- Tampilkan Semua --', value: '' },
        { text: 'Positif', value: 'pos' },
        { text: 'Negatif', value: 'neg' },
        { text: 'Netral', value: 'net' },
      ],
      selSentiment: '',

      counterBy: ['Tim Internal', 'Media Mitra', 'Mitra Lain'],
      selCounterBy: 'Tim Internal',
      actions: [
        { selCounterMedia: 'web', selCounterMediaWeb: 'portal', url: 'https://detik.com'},
        { selCounterMedia: 'socmed', selCounterMediaSocmed: 'tw', account: '@pikiran_rakyat'},
        { selCounterMedia: 'socmed', selCounterMediaSocmed: 'ig', account: '@pikiranrakyat'},
      ],

      counterMedia: [
        { text: '-- Pilih Media Penangkalan --', value: '', disabled: true },
        { text: 'Website', value: 'web' },
        { text: 'Media Sosial', value: 'socmed' },
      ],

      counterMediaWeb: [
        { text: '-- Pilih Kategori Website --', value: '', disabled: true },
        { text: 'Portal', value: 'portal' },
        { text: 'Website Pribadi', value: 'personal' },
        { text: 'Forum', value: 'forum' },
        { text: 'Blog', value: 'blog' },
      ],

      counterMediaSocmed: [
        { text: '-- Pilih Platform Media Sosial --', value: '', disabled: true },
        { text: 'Facebook', value: 'fb' },
        { text: 'Instagram', value: 'ig' },
        { text: 'Twitter', value: 'tw' },
        { text: 'YouTube', value: 'yt' },
      ],
    }
  }
}
</script>